@import "styles/constants.scss";

.bottom-panel {
  background-color: $white;
  width: 100%;
  position: absolute;
  z-index: 1050;
  bottom: 0;
  user-select: none;
  box-shadow: $large-shadow;
  transition: 360ms transform ease, 180ms opacity ease;
  transform: translateY(100%);
  will-change: transform, opacity;
  border-radius: 30px 30px 0 0;
  overflow: hidden;
  pointer-events: none;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  color: $primary-text;
  max-height: 80vh;
  // box-sizing: content-box;
  box-sizing: border-box;
  // Safe drag area
  padding-bottom: 60px;
  margin-bottom: -60px;
  opacity: 0;
  @media (prefers-color-scheme: dark) {
    background-color: $black;
    color: $secondary-text-light;
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: $primary-text-light;
    }
  }
  &.poi {
    .drag-area {
      color: $white;
      height: 7.5rem;
      .drag-handler {
        opacity: 0.35;
      }
    }
  }
  &.alert {
    background-color: $red;
    color: $white;
    .close-icon {
      color: rgba($white, 0.35);
    }
  }
  &.visible {
    transform: translateY(0px);
    pointer-events: all;
    opacity: 1;
  }
  .close-icon {
    color: rgba($black, 0.35);
    padding: 1rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
    cursor: pointer;
    transition: 180ms color ease;
    &:hover,
    &:active {
      color: $primary;
    }
  }
  .drag-area {
    height: 3.75rem;
    cursor: grab;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    .drag-handler {
      background-color: currentColor;
      border-radius: 0.125rem;
      height: 0.25rem;
      left: 50%;
      opacity: 0.1;
      position: absolute;
      top: 0.625rem;
      transform: translateX(-50%);
      width: 5rem;
      @media (prefers-color-scheme: dark) {
        background-color: $white;
        opacity: 0.15;
      }
    }
  }
  @media screen and (min-width: 767px) {
    padding-bottom: 0;
    margin: auto;
    bottom: unset;
    top: 50%;
    opacity: 0;
    border-radius: 30px;
    transform: translateY(-20%);
    .drag-area {
      display: none;
    }
    &.visible {
      opacity: 1;
      transform: translateY(-50%);
    }
  }
}
