@import "./constants.scss";

:root {
  --primary: #{$primary};
  --secondary: #{$secondary};
  --white: #{$white};
  --black: #{$black};
}

@media (prefers-color-scheme: dark) {
  :root {
    --primary: #{$primary};
    --secondary: #{$black};
    --white: #{$black};
    --black: #{$white};
  }
}
* {
  box-sizing: border-box;
}
html,
body {
  height: 100%;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  font-family: $font-family;
}
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  margin: 0;
  padding: 0;
}
code {
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  text-decoration: underline dotted;
  color: currentColor;
}
.events {
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 50%;
  height: 100%;
}
.events_lost {
  box-shadow: 0px 0px 2px 5px rgba(255, 0, 0, 0.48);
}
.action {
  color: $primary;
  cursor: pointer;
  display: inline-block;
  font-size: 0.875rem;
  margin: -0.25rem;
  padding: 0.25rem;
  z-index: 3;
  &:hover {
    color: lighten($primary, 10%);
  }
}
.app {
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
  position: relative;
  .map-container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 50;
  }
  .map-actions {
    height: 100%;
    padding: 1.875rem 1.25rem;
    pointer-events: none;
    position: relative;
    width: 100%;
    z-index: 100;
    overflow: hidden;
    .container {
      position: relative;
      width: 100%;
      height: 100%;
      > * {
        pointer-events: all;
      }
      .centerpiece {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
      .right-panel {
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        .fab {
          margin-top: 1.25rem;
        }
      }
    }
  }
  .loading-view {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $accent;
  }
}
.loader {
  max-width: 3rem;
  max-height: 3rem;
}
/* Helpers */

// Safari hack, can't listen or attach events for elements with display:none;
.hidden {
  height: 0px;
  overflow: hidden;
  pointer-events: none;
  width: 0px;
  position: absolute;
  z-index: -99;
  opacity: 0;
}
@keyframes LoadingAnimation {
  from {
    background-size: 20%;
  }
  to {
    background-size: 50%;
  }
}
.menus {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
