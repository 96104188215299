@use "sass:math";
@import "styles/constants.scss";

.view-post {
  padding: 0;
  .gallery-container {
    position: relative;
    .gallery {
      .item {
        background-color: rgba($black, 0.35);
        display: block;
        object-fit: cover;
        width: 100%;
        min-height: 280px;
        transition: 180ms ease;
        z-index: 50;
        position: relative;
        height: 100%;
        max-height: 60vh;
        user-select: none;
        pointer-events: none;
      }
    }
    .pagination {
      align-items: center;
      bottom: 0;
      display: flex;
      justify-content: center;
      left: 0;
      position: absolute;
      width: 100%;
      padding: 0.5rem;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.25) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      > .dot {
        $dot-size: 0.375rem;
        width: $dot-size;
        height: $dot-size;
        background-color: $white;
        border-radius: math.div($dot-size, 2);
        opacity: 0.45;
        margin: 0.125rem;
        transition: 360ms opacity ease;
        &.active {
          opacity: 1;
        }
      }
    }
  }
  .content-container {
    padding: 1.875rem;
    display: flex;
    flex-direction: column;
    &:empty {
      display: none;
    }
    .comment {
      margin-bottom: 1em;
      p {
        margin: 0;
      }
    }
    .datetime {
      font-size: 0.75rem;
      opacity: 0.75;
      margin-left: auto;
    }
  }
  .phone-container {
    border-top: 1px solid rgba($black, 0.1);
    .phone-button {
      display: block;
      padding: 1.5rem;
      text-decoration: none;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      display: flex;
      font-size: 1.25rem;
      transition: 180ms ease;
      .icon {
        margin-right: 0.625rem;
      }
      &:hover {
        background-color: white;
        color: $red;
      }
    }
  }
}
