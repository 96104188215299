@use "sass:math";
@import "styles/constants.scss";

.create-post {
  padding: 0;
  .content-container {
    padding: 1.875rem;
    overflow: auto;
    max-height: 75vh;
    .block {
      margin-top: 1.25rem;
      display: flex;
      flex-direction: column;
    }
  }
  h1 {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.1;
  }
  .button {
    background-color: $primary;
    color: $secondary;
    border-radius: 0.25rem;
    padding: 0.625rem 1rem;
    cursor: pointer;
    transition: 180ms background-color ease;
    &.submit {
      margin-left: auto;
    }
    &:hover {
      background-color: lighten($primary, 10%);
    }
    &.attached {
      display: block;
      text-align: center;
      text-transform: uppercase;
      background-color: $accent;
      letter-spacing: 0.125rem;
      font-size: 0.75rem;
      border-radius: 0 0 1.25rem 1.25rem;
    }
  }
  label {
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
  }
  .type-selector {
    height: 32px;
    width: 100%;
    display: flex;
    box-shadow: 0 0 0 1px $blue;
    border-radius: 0.25rem;
    overflow: hidden;
    .type {
      flex-basis: 50%;
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.75rem;
      font-weight: 600;
      text-transform: uppercase;
      cursor: pointer;
      color: $blue;
      transition: 180ms ease;
      &:hover {
        background-color: rgba($blue, 0.05);
      }
      &:first-child {
        border-right: 1px solid $blue;
      }
      &.selected {
        background-color: $blue;
        color: $white;
      }
    }
  }
  .description {
    font-family: inherit;
    background-color: transparent;
    color: currentColor;
    width: 100%;
    border-radius: 0.25rem;
    padding: 0.625rem;
    min-height: 80px;
    border: 1px solid rgba($secondary-text, 0.25);
    @media (prefers-color-scheme: dark) {
      border: 1px solid rgba($secondary-text-light, 0.25);
    }
    &:focus {
      outline: 2px solid $blue;
      -webkit-appearance: none;
    }
  }
  .images {
    .item {
      height: 80px;
      width: 80px;
      object-fit: cover;
      margin: 2px;
      border-radius: 0.25rem;
    }
    .add-image {
      height: 80px;
      width: 80px;
      display: flex;
      margin: 2px;
      align-items: center;
      justify-content: center;
      color: $secondary-text;
      box-shadow: 0 0 0 1px rgba($secondary-text, 0.25);
      @media (prefers-color-scheme: dark) {
        box-shadow: 0 0 0 1px rgba($secondary-text-light, 0.25);
      }
      border-radius: 0.25rem;
    }
  }
}
