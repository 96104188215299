
.minimap {
  height: 120px;
  width: 100%;
  border-radius: 1.25rem;
}
.bottom-panel {
  background-color: #ffffff;
  width: 100%;
  position: absolute;
  z-index: 1050;
  bottom: 0;
  user-select: none;
  box-shadow: 0 0.25rem 1.25rem 0 rgba(33, 33, 33, 0.1);
  transition: 360ms transform ease, 180ms opacity ease;
  transform: translateY(100%);
  will-change: transform, opacity;
  border-radius: 30px 30px 0 0;
  overflow: hidden;
  pointer-events: none;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  color: #393e41;
  max-height: 80vh;
  box-sizing: border-box;
  padding-bottom: 60px;
  margin-bottom: -60px;
  opacity: 0;
}
@media (prefers-color-scheme: dark) {
  .bottom-panel {
    background-color: #212121;
    color: #9ba3a8;
  }
  .bottom-panel h1,
.bottom-panel h2,
.bottom-panel h3,
.bottom-panel h4,
.bottom-panel h5 {
    color: #b8c0c5;
  }
}
.bottom-panel.poi .drag-area {
  color: #ffffff;
  height: 7.5rem;
}
.bottom-panel.poi .drag-area .drag-handler {
  opacity: 0.35;
}
.bottom-panel.alert {
  background-color: #ba1b1d;
  color: #ffffff;
}
.bottom-panel.alert .close-icon {
  color: rgba(255, 255, 255, 0.35);
}
.bottom-panel.visible {
  transform: translateY(0px);
  pointer-events: all;
  opacity: 1;
}
.bottom-panel .close-icon {
  color: rgba(33, 33, 33, 0.35);
  padding: 1rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
  cursor: pointer;
  transition: 180ms color ease;
}
.bottom-panel .close-icon:hover, .bottom-panel .close-icon:active {
  color: #f50093;
}
.bottom-panel .drag-area {
  height: 3.75rem;
  cursor: grab;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}
.bottom-panel .drag-area .drag-handler {
  background-color: currentColor;
  border-radius: 0.125rem;
  height: 0.25rem;
  left: 50%;
  opacity: 0.1;
  position: absolute;
  top: 0.625rem;
  transform: translateX(-50%);
  width: 5rem;
}
@media (prefers-color-scheme: dark) {
  .bottom-panel .drag-area .drag-handler {
    background-color: #ffffff;
    opacity: 0.15;
  }
}
@media screen and (min-width: 767px) {
  .bottom-panel {
    padding-bottom: 0;
    margin: auto;
    bottom: unset;
    top: 50%;
    opacity: 0;
    border-radius: 30px;
    transform: translateY(-20%);
  }
  .bottom-panel .drag-area {
    display: none;
  }
  .bottom-panel.visible {
    opacity: 1;
    transform: translateY(-50%);
  }
}
.create-post {
  padding: 0;
}
.create-post .content-container {
  padding: 1.875rem;
  overflow: auto;
  max-height: 75vh;
}
.create-post .content-container .block {
  margin-top: 1.25rem;
  display: flex;
  flex-direction: column;
}
.create-post h1 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.1;
}
.create-post .button {
  background-color: #f50093;
  color: white;
  border-radius: 0.25rem;
  padding: 0.625rem 1rem;
  cursor: pointer;
  transition: 180ms background-color ease;
}
.create-post .button.submit {
  margin-left: auto;
}
.create-post .button:hover {
  background-color: #ff29a9;
}
.create-post .button.attached {
  display: block;
  text-align: center;
  text-transform: uppercase;
  background-color: #3f0c80;
  letter-spacing: 0.125rem;
  font-size: 0.75rem;
  border-radius: 0 0 1.25rem 1.25rem;
}
.create-post label {
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
}
.create-post .type-selector {
  height: 32px;
  width: 100%;
  display: flex;
  box-shadow: 0 0 0 1px #0086ff;
  border-radius: 0.25rem;
  overflow: hidden;
}
.create-post .type-selector .type {
  flex-basis: 50%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  color: #0086ff;
  transition: 180ms ease;
}
.create-post .type-selector .type:hover {
  background-color: rgba(0, 134, 255, 0.05);
}
.create-post .type-selector .type:first-child {
  border-right: 1px solid #0086ff;
}
.create-post .type-selector .type.selected {
  background-color: #0086ff;
  color: #ffffff;
}
.create-post .description {
  font-family: inherit;
  background-color: transparent;
  color: currentColor;
  width: 100%;
  border-radius: 0.25rem;
  padding: 0.625rem;
  min-height: 80px;
  border: 1px solid rgba(111, 121, 127, 0.25);
}
@media (prefers-color-scheme: dark) {
  .create-post .description {
    border: 1px solid rgba(155, 163, 168, 0.25);
  }
}
.create-post .description:focus {
  outline: 2px solid #0086ff;
  -webkit-appearance: none;
}
.create-post .images .item {
  height: 80px;
  width: 80px;
  object-fit: cover;
  margin: 2px;
  border-radius: 0.25rem;
}
.create-post .images .add-image {
  height: 80px;
  width: 80px;
  display: flex;
  margin: 2px;
  align-items: center;
  justify-content: center;
  color: #6f797f;
  box-shadow: 0 0 0 1px rgba(111, 121, 127, 0.25);
  border-radius: 0.25rem;
}
@media (prefers-color-scheme: dark) {
  .create-post .images .add-image {
    box-shadow: 0 0 0 1px rgba(155, 163, 168, 0.25);
  }
}
.main-menu {
  padding: 3.125rem 2.5rem 1.875rem 2.5rem;
  position: relative;
  background-image: url(cato.301fa93c.png);
  background-size: 50% auto;
  background-position: top right;
  background-repeat: no-repeat;
}
@media (prefers-color-scheme: dark) {
  .main-menu {
    color: #ffffff;
    background-image: url(cato-inverted.cb96db5b.png);
  }
}
.main-menu .logo-container {
  margin-bottom: 1.875rem;
}
.main-menu .logo-container .logo {
  max-height: 3.125rem;
}
.main-menu .menu-container {
  margin-bottom: 3.125rem;
  max-width: 50vw;
}
.main-menu .menu-container .menu {
  display: flex;
  flex-direction: column;
  margin: -0.625rem;
}
.main-menu .menu-container .menu .item {
  padding: 0.625rem;
  line-height: 1;
  text-decoration: none;
  transition: 180ms ease;
  cursor: pointer;
}
.main-menu .menu-container .menu .item:hover {
  color: #f50093;
}
.main-menu .auth-container {
  display: flex;
  margin-top: 3.125rem;
  align-items: center;
}
.main-menu .auth-container .user {
  display: flex;
  font-weight: 500;
  margin-right: auto;
  align-items: center;
}
.main-menu .auth-container .user > .icon {
  margin-right: 0.5rem;
}
@keyframes fadeIn {
  0% {
    transform: translateY(40px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.menu-view {
  animation: 360ms fadeIn ease;
  max-height: 0px;
  max-height: 60vh;
  padding: 2.5rem 2.5rem 1.875rem 2.5rem;
  overflow: auto;
}
.menu-view h4 {
  font-size: 1rem;
  margin: 2rem 0 1rem;
  font-weight: 600;
}
.menu-view h4:first-of-type {
  margin-top: 0;
}
.menu-view p {
  font-size: 1rem;
  line-height: 1.4;
  margin: 1rem 0;
}
.menu-view img {
  display: block;
  max-width: 100%;
  margin: 1rem auto;
}
.authorization {
  padding: 3.125rem 2.5rem 1.875rem 2.5rem;
}
.authorization .firebaseui-container {
  box-shadow: none;
  max-width: unset;
}
.authorization .firebaseui-container .firebaseui-card-header {
  border-bottom: none;
}
.authorization .firebaseui-container .firebaseui-tos {
  margin: 1.25rem 0 0;
  opacity: 0.5;
}
@media (prefers-color-scheme: dark) {
  .authorization .firebaseui-container {
    background-color: #212121;
    color: #9ba3a8;
  }
  .authorization .firebaseui-container * {
    color: #9ba3a8 !important;
  }
  .authorization .firebaseui-container .firebaseui-subtitle,
.authorization .firebaseui-container .firebaseui-text {
    color: #9ba3a8;
  }
}
.authorization [class^=firebaseui-] {
  padding: 0;
}
.back.action {
  align-items: center;
  color: #f50093;
  cursor: pointer;
  display: inline-flex;
  font-size: 0.875rem;
  margin: -1.875rem -1.875rem 0;
  padding: 1.875rem;
  position: relative;
  transition: 180ms ease;
}
.back.action:hover, .back.action:active {
  color: #ff43b4;
}
.poi-view {
  padding: 2rem 2.5rem 1.875rem 2.5rem;
}
.poi-view .title {
  display: flex;
  align-items: center;
}
.poi-view .title .logo {
  max-width: 75%;
  max-height: 24px;
  margin: 0 0 0.375rem 0;
}
.poi-view .title .content {
  margin-right: auto;
}
.poi-view .title .content h1 {
  margin: 0 0 0.375rem 0;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.1;
}
.poi-view .title .content p {
  margin: 0;
  font-size: 0.875rem;
  color: #6f797f;
}
.poi-view .title .share-icon {
  color: #0086ff;
  line-height: 1;
  z-index: 10;
  position: relative;
  transition: 180ms color ease;
}
.poi-view .title .share-icon:hover, .poi-view .title .share-icon:active {
  color: rgba(0, 134, 255, 0.65);
}
.poi-view .meta {
  position: relative;
  margin-top: 1.25rem;
}
.poi-view .gallery-item {
  max-width: 100%;
  display: block;
  max-height: 100%;
}
.poi-view .gallery-container {
  margin-top: 1.875rem;
  position: relative;
}
.poi-view .gallery-container .pagination {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  width: 100%;
  padding: 0.5rem;
}
.poi-view .gallery-container .pagination > .dot {
  width: 0.375rem;
  height: 0.375rem;
  background-color: #ffffff;
  border-radius: 0.1875rem;
  opacity: 0.45;
  margin: 0.125rem;
  transition: 360ms opacity ease;
}
.poi-view .gallery-container .pagination > .dot.active {
  opacity: 1;
}
.poi-view .gallery {
  width: 100%;
}
.poi-view .timetable {
  width: 75%;
  font-size: 0.875rem;
}
.poi-view .timetable .title {
  padding: 0.375rem 0;
  text-transform: uppercase;
  font-weight: bold;
}
.poi-view .timetable .row {
  display: flex;
  padding: 0.375rem 0;
}
.poi-view .timetable .row .day {
  margin-right: auto;
}
.poi-view .timetable .row.weekend {
  color: #6f797f;
}
.poi-view .timetable .row.today {
  opacity: 1;
  color: #3f0c80;
  font-weight: bold;
}
@media screen and (min-width: 767px) {
  .poi-view {
    padding: 1.875rem 2.5rem 1.875rem 2.5rem;
  }
}
.view-post {
  padding: 0;
}
.view-post .gallery-container {
  position: relative;
}
.view-post .gallery-container .gallery .item {
  background-color: rgba(33, 33, 33, 0.35);
  display: block;
  object-fit: cover;
  width: 100%;
  min-height: 280px;
  transition: 180ms ease;
  z-index: 50;
  position: relative;
  height: 100%;
  max-height: 60vh;
  user-select: none;
  pointer-events: none;
}
.view-post .gallery-container .pagination {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  width: 100%;
  padding: 0.5rem;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
}
.view-post .gallery-container .pagination > .dot {
  width: 0.375rem;
  height: 0.375rem;
  background-color: #ffffff;
  border-radius: 0.1875rem;
  opacity: 0.45;
  margin: 0.125rem;
  transition: 360ms opacity ease;
}
.view-post .gallery-container .pagination > .dot.active {
  opacity: 1;
}
.view-post .content-container {
  padding: 1.875rem;
  display: flex;
  flex-direction: column;
}
.view-post .content-container:empty {
  display: none;
}
.view-post .content-container .comment {
  margin-bottom: 1em;
}
.view-post .content-container .comment p {
  margin: 0;
}
.view-post .content-container .datetime {
  font-size: 0.75rem;
  opacity: 0.75;
  margin-left: auto;
}
.view-post .phone-container {
  border-top: 1px solid rgba(33, 33, 33, 0.1);
}
.view-post .phone-container .phone-button {
  display: block;
  padding: 1.5rem;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  display: flex;
  font-size: 1.25rem;
  transition: 180ms ease;
}
.view-post .phone-container .phone-button .icon {
  margin-right: 0.625rem;
}
.view-post .phone-container .phone-button:hover {
  background-color: white;
  color: #ba1b1d;
}
.fab {
  background-color: var(--primary);
  color: #ffffff;
  width: 64px;
  height: 64px;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0.125rem 0.125rem 0 rgba(33, 33, 33, 0.1);
  cursor: pointer;
  transition: 180ms filter ease;
  -webkit-tap-highlight-color: transparent;
}
.fab:hover {
  filter: brightness(0.97);
}
.fab:active {
  filter: brightness(0.95);
  opacity: 0.95;
}
.fab.small {
  width: 44px;
  height: 44px;
  background-color: var(--secondary);
  border-radius: 22px;
  color: var(--black);
}
.fab svg.navigation {
  margin-top: 2px;
  margin-right: 2px;
}
.fab.active {
  background-color: #0086ff;
  color: #ffffff;
}
/*
 * react-circular-progressbar styles
 * All of the styles in this file are configurable!
 */

.CircularProgressbar {
  /*
   * This fixes an issue where the CircularProgressbar svg has
   * 0 width inside a "display: flex" container, and thus not visible.
   */
  width: 100%;
  /*
   * This fixes a centering issue with CircularProgressbarWithChildren:
   * https://github.com/kevinsqi/react-circular-progressbar/issues/94
   */
  vertical-align: middle;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #3e98c7;
  stroke-linecap: round;
  -webkit-transition: stroke-dashoffset 0.5s ease 0s;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #d6d6d6;
  /* Used when trail is not full diameter, i.e. when props.circleRatio is set */
  stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #3e98c7;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}

.CircularProgressbar .CircularProgressbar-background {
  fill: #d6d6d6;
}

/*
 * Sample background styles. Use these with e.g.:
 *
 *   <CircularProgressbar
 *     className="CircularProgressbar-inverted"
 *     background
 *     percentage={50}
 *   />
 */
.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-background {
  fill: #3e98c7;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
  stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
  stroke: transparent;
}

:root {
  --primary: #f50093;
  --secondary: white;
  --white: #ffffff;
  --black: #212121;
}

@media (prefers-color-scheme: dark) {
  :root {
    --primary: #f50093;
    --secondary: #212121;
    --white: #212121;
    --black: #ffffff;
  }
}
* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

code {
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: underline dotted;
  color: currentColor;
}

.events {
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 50%;
  height: 100%;
}

.events_lost {
  box-shadow: 0px 0px 2px 5px rgba(255, 0, 0, 0.48);
}

.action {
  color: #f50093;
  cursor: pointer;
  display: inline-block;
  font-size: 0.875rem;
  margin: -0.25rem;
  padding: 0.25rem;
  z-index: 3;
}
.action:hover {
  color: #ff29a9;
}

.app {
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
  position: relative;
}
.app .map-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 50;
}
.app .map-actions {
  height: 100%;
  padding: 1.875rem 1.25rem;
  pointer-events: none;
  position: relative;
  width: 100%;
  z-index: 100;
  overflow: hidden;
}
.app .map-actions .container {
  position: relative;
  width: 100%;
  height: 100%;
}
.app .map-actions .container > * {
  pointer-events: all;
}
.app .map-actions .container .centerpiece {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.app .map-actions .container .right-panel {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
}
.app .map-actions .container .right-panel .fab {
  margin-top: 1.25rem;
}
.app .loading-view {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3f0c80;
}

.loader {
  max-width: 3rem;
  max-height: 3rem;
}

/* Helpers */
.hidden {
  height: 0px;
  overflow: hidden;
  pointer-events: none;
  width: 0px;
  position: absolute;
  z-index: -99;
  opacity: 0;
}

@keyframes LoadingAnimation {
  from {
    background-size: 20%;
  }
  to {
    background-size: 50%;
  }
}
.menus {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
/*# sourceMappingURL=index.23bcebf4.css.map */
