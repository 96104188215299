$font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

$primary: #f50093;
$accent: #3f0c80;
$secondary: white;
$blue: #0086ff;
$red: #ba1b1d;

$primary-text: #393e41;
$secondary-text: #6f797f;

$primary-text-light: #b8c0c5;
$secondary-text-light: #9ba3a8;

$white: #ffffff;
$black: #212121;

$map-shadow: 0 0.125rem 0.125rem 0 rgba($black, 0.1);
$large-shadow: 0 0.25rem 1.25rem 0 rgba($black, 0.1);
