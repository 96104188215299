@use "sass:math";
@import "styles/constants.scss";

.poi-view {
  padding: 2rem 2.5rem 1.875rem 2.5rem;
  .title {
    display: flex;
    align-items: center;
    .logo {
      max-width: 75%;
      max-height: 24px;
      margin: 0 0 0.375rem 0;
    }
    .content {
      margin-right: auto;
      h1 {
        margin: 0 0 0.375rem 0;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.1;
      }
      p {
        margin: 0;
        font-size: 0.875rem;
        color: $secondary-text;
      }
    }
    .share-icon {
      color: $blue;
      line-height: 1;
      z-index: 10;
      position: relative;
      transition: 180ms color ease;
      &:hover,
      &:active {
        color: rgba($blue, 0.65);
      }
    }
  }
  .meta {
    position: relative;
    margin-top: 1.25rem;
  }
  .gallery-item {
    max-width: 100%;
    display: block;
    max-height: 100%;
  }
  .gallery-container {
    margin-top: 1.875rem;
    position: relative;
    .pagination {
      align-items: center;
      bottom: 0;
      display: flex;
      justify-content: center;
      left: 0;
      position: absolute;
      width: 100%;
      padding: 0.5rem;
      > .dot {
        $dot-size: 0.375rem;
        width: $dot-size;
        height: $dot-size;
        background-color: $white;
        border-radius: math.div($dot-size, 2);
        opacity: 0.45;
        margin: 0.125rem;
        transition: 360ms opacity ease;
        &.active {
          opacity: 1;
        }
      }
    }
  }
  .gallery {
    width: 100%;
  }
  .timetable {
    width: 75%;
    font-size: 0.875rem;
    .title {
      padding: 0.375rem 0;
      text-transform: uppercase;
      font-weight: bold;
    }
    .row {
      display: flex;
      padding: 0.375rem 0;
      .day {
        margin-right: auto;
      }
      &.weekend {
        color: $secondary-text;
      }
      &.today {
        opacity: 1;
        color: $accent;
        font-weight: bold;
      }
    }
  }
  @media screen and (min-width: 767px) {
    padding: 1.875rem 2.5rem 1.875rem 2.5rem;
  }
}
