@import "styles/constants.scss";

.main-menu {
  padding: 3.125rem 2.5rem 1.875rem 2.5rem;
  position: relative;
  background-image: url(./assets/cato.png);
  background-size: 50% auto;
  background-position: top right;
  background-repeat: no-repeat;
  @media (prefers-color-scheme: dark) {
    color: $white;
    background-image: url(./assets/cato-inverted.png);
  }
  .logo-container {
    margin-bottom: 1.875rem;
    .logo {
      max-height: 3.125rem;
    }
  }
  .menu-container {
    margin-bottom: 3.125rem;
    max-width: 50vw;
    .menu {
      $menu-safe-area: 0.625rem;
      display: flex;
      flex-direction: column;
      margin: -$menu-safe-area;
      .item {
        padding: $menu-safe-area;
        line-height: 1;
        text-decoration: none;
        transition: 180ms ease;
        cursor: pointer;
        &:hover {
          color: $primary;
        }
      }
    }
  }
  .auth-container {
    display: flex;
    margin-top: 3.125rem;
    align-items: center;
    .user {
      display: flex;
      font-weight: 500;
      margin-right: auto;
      align-items: center;
      > .icon {
        margin-right: 0.5rem;
      }
    }
  }
}
@keyframes fadeIn {
  0% {
    transform: translateY(40px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.menu-view {
  animation: 360ms fadeIn ease;
  max-height: 0px;
  max-height: 60vh;
  padding: 2.5rem 2.5rem 1.875rem 2.5rem;
  overflow: auto;
  h4 {
    font-size: 1rem;
    margin: 2rem 0 1rem;
    font-weight: 600;
    &:first-of-type {
      margin-top: 0;
    }
  }
  p {
    font-size: 1rem;
    line-height: 1.4;
    margin: 1rem 0;
  }
  img {
    display: block;
    max-width: 100%;
    margin: 1rem auto;
  }
}
.authorization {
  padding: 3.125rem 2.5rem 1.875rem 2.5rem;
  .firebaseui-container {
    box-shadow: none;
    max-width: unset;
    .firebaseui-card-header {
      border-bottom: none;
    }
    .firebaseui-tos {
      margin: 1.25rem 0 0;
      opacity: 0.5;
    }
    @media (prefers-color-scheme: dark) {
      background-color: $black;
      color: $secondary-text-light;
      * {
        color: $secondary-text-light !important;
      }
      .firebaseui-subtitle,
      .firebaseui-text {
        color: $secondary-text-light;
      }
    }
  }
  [class^="firebaseui-"] {
    padding: 0;
  }
}
.back.action {
  align-items: center;
  color: $primary;
  cursor: pointer;
  display: inline-flex;
  font-size: 0.875rem;
  margin: -1.875rem -1.875rem 0;
  padding: 1.875rem;
  position: relative;
  transition: 180ms ease;
  &:hover,
  &:active {
    color: lighten($primary, 15%);
  }
}
