@use "sass:math";
@import "styles/constants.scss";

$size-medium: 64px;
$size-small: 44px;

.fab {
  background-color: var(--primary);
  color: $white;
  width: $size-medium;
  height: $size-medium;
  border-radius: math.div($size-medium, 2);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: $map-shadow;
  cursor: pointer;
  transition: 180ms filter ease;
  -webkit-tap-highlight-color: transparent;
  &:hover {
    filter: brightness(0.97);
  }
  &:active {
    filter: brightness(0.95);
    opacity: 0.95;
  }
  &.small {
    width: $size-small;
    height: $size-small;
    background-color: var(--secondary);
    border-radius: math.div($size-small, 2);
    color: var(--black);
  }
  svg.navigation {
    margin-top: 2px;
    margin-right: 2px;
  }
  &.active {
    background-color: $blue;
    color: $white;
  }
}
